
import Vue from "vue";
import {
	isRequired,
	isNumber,
	isAfterToday,
	isAfterCompare,
} from "../../../../services/rule-services";
import DatePicker from "../../../../components/Content/DatePicker.vue";
import DividerForm from "../../../../components/Content/DividerForm.vue";
import CardTextField from "../../../../components/Content/CardTextField.vue";
import CardAutocomplete from "../../../../components/Content/CardAutocomplete.vue";
import { AssociationDataCreate } from "../../../../interfaces/creativeAssociation";
import { isEmpty, isNull, isUndefined } from "lodash";
import { LineItemFilters } from "../../../../interfaces/line_item";
import { SortingOption } from "../../../../interfaces/paginated";
import { getCreativeTypeByTemplateId } from "../../../../utils/resolveObjectArray";
import { initAssociationCreative } from "../../../../utils/initData";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { mapActions } from "vuex";

export default Vue.extend({
	name: "AssociatedLineForm",
	props: {
		creative: {
			type: Object,
			default: function () {
				return {};
			},
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		CardTextField,
		DatePicker,
		DividerForm,
		CardAutocomplete,
	},
	data: () => ({
		valid: true,
		line_association: {} as AssociationDataCreate,
		line_item_id_rules: [],
		start_date_rules: [],
		end_date_rules: [],
		filters: {} as LineItemFilters,
		options: {
			sort: "",
			order: "asc",
		} as SortingOption,
		fechaValid: false,
		resources:{
			line_items: []
		}
	}),
	created() {
		this.$nextTick(async function () {
			this.line_association = initAssociationCreative(this.creative);
		});
	},
	mounted() {
		this.$nextTick(async function () {
			this.setCreativeToAssign();
		});
	},
	beforeDestroy() {
		this.$store.state.line_item.lineItems = [];
	},
	computed: {
		getRules() {
			return {
				isRequired,
				isNumber,
				isAfterToday,
				isAfterCompare,
			};
		},
		getToday() {
			return this.moment().format("YYYY-MM-DD HH:mm:ss");
		},
		getAssociationMinDate() {
			return this.moment(this.line_association.start_date).format(
				"YYYY-MM-DD HH:mm:ss"
			);
		},
		getLineItems() {
			return this.$store.state.line_item.lineItems;
		},
		getLineItemsData() {
			return this.resources.line_items;
		},
		getCreativeTypes() {
			return this.$store.state.creative.creative_types;
		},
		
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),

		...mapActions("line_item", ["listCreativeAssociated"]),

		getDate(fecha: any){
			if(isNull(fecha) || isUndefined(fecha)) return undefined;
			return this.moment(String(fecha)).format("YYYY-MM-DD HH:mm:ss");
		},

		async getCreativeTypeDescription() {
			return getCreativeTypeByTemplateId(
				this.getCreativeTypes,
				this.creative.creative_type_id
			)?.name;
		},
		async setFilter(params: { key: string | number; value: any }) {
			this.filters = {} as LineItemFilters;
			this.filters[params.key] = params.value || "";
		},
		async validate() {
			return await this.$refs.form.validate();
		},

		async addValidations() {
			this.line_item_id_rules = [this.getRules.isRequired];
			this.start_date_rules = this.line_association.start_date 
									? [this.getRules.isAfterToday]
									: [];

			this.end_date_rules = this.line_association.end_date 
								? [this.getRules.isAfterCompare(this.line_association.end_date,
																this.line_association.start_date)]
								: [];

			/*
			this.start_date_rules = [
				this.getRules.isRequired,
				this.getRules.isAfterToday,
			];

			this.end_date_rules = [
				this.getRules.isRequired,
				this.getRules.isAfterCompare(
					this.line_association.end_date,
					this.line_association.start_date
				),
			];
			*/
		},

		/**
		 * Actions
		 * handleSubmitAssociated
		 */
		async handleSubmitAssociated() {
			try {
				if(this.line_association.start_date){
					if(!this.line_association.end_date){
						this.fechaValid = true;
						return;
					}
				}
				if(this.line_association.end_date){
					if(!this.line_association.start_date){
						this.fechaValid = true;
						return;
					}
				}
				this.fechaValid = false;
				await this.addValidations();
				if (!(await this.validate())) return;
				await this.setLoadingData(TypeLoading.loading);
				await this.associateLineItem().then((response) => {
					(this as any).dispatchShowLineItem((this as any).creative.id);
				});
				await this.setLoadingData();
				await this.clearRules();
				this.line_association = initAssociationCreative(this.creative);
				this.$emit("update-table");
			} catch (error) {
				console.error("handleSubmitAssociated", { error: error });
				await this.clearRules();
				await this.setLoadingData();
			}
		},
		async setCreativeToAssign() {
			this.line_association.creative_id = this.creative.id;
			this.filters = {
				line_item_type: {
					description: this.creative?.type?.description,
				},
				advertiser_id: this.creative.creative_advertiser.advertiser_id,
			} as LineItemFilters;
		},
		async fetchLineItems() {
			if (!isEmpty(this.getLineItems)) return;
			await this.setLoadingData(TypeLoading.loading);
			await this.$store.dispatch("line_item/all", {
				filters: this.filters,
				options: this.options as SortingOption,
			});
			await this.setLoadingData();
		},
		async fetchLineItemsData() {
			if (!isEmpty(this.getLineItems)) return;
			await this.setLoadingData(TypeLoading.loading);
			this.resources.line_items = await this.listCreativeAssociated(this.creative.id);
			await this.setLoadingData();
		},
		async associateLineItem() {
			return await this.$store.dispatch(
				"creative/associateLineItem",
				await this.prepareAssociationDataCreate()
			);
		},
		async prepareAssociationDataCreate() {
			return {
				creative_id: this.creative.id,
				line_item_id: this.line_association.line_item_id,
				start_date: this.getDate(this.line_association.start_date), 
				end_date: this.getDate(this.line_association.end_date),
			} as AssociationDataCreate;
		},
		async clearRules(){
			this.line_item_id_rules = [];
			this.start_date_rules = [];
			this.end_date_rules = [];
		},

		async dispatchShowLineItem(id: any) {
			return await (this as any).$store.dispatch("creative/show", id, {
				root: true,
			});
		},
	},
	watch: {
		"creative.creative_type_id": function (newVal, oldVal) {
			this.line_association = initAssociationCreative(this.creative);
			this.setCreativeToAssign();
		},
	},
});
