import {
	DeviceConfig,
	DeviceAction,
	DeviceSize,
	OrientationType,
} from "@/interfaces/device";
import Vue from "vue";
import { deviceConfig } from "./utils";
export default Vue.extend({
	name: "PreviewDevice",
	model: {},
	props: {
		src: {
			type: String,
			default: "",
		},
		config: {
			type: Object,
			default: () => {},
		},
		size: {
			type: String,
			default: "",
		},
		rotate: {
			type: Boolean,
			default: true,
		},
		isPreview: {
			type: Boolean,
			default: false,
		},
		showControl: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	data: () => ({
		sizes: [DeviceSize.LARGE, DeviceSize.MEDIUM, DeviceSize.SMALL],
		devices: deviceConfig(),
	}),
	created() {
		this.$nextTick(async () => {
			this.$emit("preview-data", {
				size: this.size,
				rotate: this.config?.isRotate,
			});
		});
	},
	mounted() {},
	computed: {
		deviceCase() {
			if (!this.config.enableRotate)
				return `${this.config.rotateDefault} ${this.size}`;
			return `${
				this.rotate
					? OrientationType.HORIZONTAL
					: OrientationType.VERTICAL
			} ${this.size}`;
		},
		active() {
			return DeviceAction.ACTIVE;
		},
	},
	methods: {
		showRotate(size: string) {
			if (size !== DeviceConfig.ROTATE) return true;
			return this.config.enableRotate;
		},
		setSize(size: string) {
			this.$emit("preview-data", {
				size: size,
			});
		},
		toggleRotate() {
			this.$emit("preview-data", {
				rotate: !this.rotate,
			});
		},
		handleClickDevice(size: string) {
			if (size === DeviceConfig.ROTATE) {
				if (!this.config.enableRotate) return;
				this.toggleRotate();
				return;
			}
			this.setSize(size);
		},
		getImageByDeviceSelected(card: any) {
			if (card.size === DeviceConfig.ROTATE)
				return this.rotate ? card.src.active : card.src.image;
			return card.size === this.size ? card.src.active : card.src.image;
		},
		handleToggleModal() {
			this.$emit("toggle");
		},
	},
});
