import Vue from "vue";
import { isUndefined, isNull, isEmpty, filter, find } from "lodash";
// @ts-ignore
import DatePicker from "@/components/Content/DatePicker.vue";
// @ts-ignore
import DataPickerStarTime from "@/components/Content/DataPickerStarTime.vue";
// @ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
// @ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
// @ts-ignore
import CardSwitch from "@/components/Content/CardSwitch.vue";
// @ts-ignore
import RadioButton from "@/components/Content/RadioButton.vue";
// @ts-ignore
import { getError } from "@/utils/resolveObjectArray";
// @ts-ignore
import Separator from "@/components/Content/Separator.vue";
// @ts-ignore
import DividerForm from "@/components/Content/DividerForm.vue";
// @ts-ignore
import CardActions from "@/components/Content/CardActions.vue";
// @ts-ignore
import CardTextarea from "@/components/Content/CardTextarea.vue";
// @ts-ignore
// import { getToday } from "@/services/date-service";
// @ts-ignore
import { hasFieldByTemplateId } from "@/utils/fields";
// @ts-ignore
import ArrayListItem from "@/components/Content/ArrayListItem.vue";
// @ts-ignore
import VastEvent from "@/components/Content/VastEvent.vue";
// @ts-ignore
import ProgressEvent from "@/components/Content/ProgressEvent.vue";
// @ts-ignore
import AssociatedLineForm from "@/views/Admin/Creatives/Create/associatedLineForm.vue";
// @ts-ignore
import AssociationTable from "@/views/Admin/Creatives/Associations/AssociationTable.vue";
// @ts-ignore
import AssociationTableBase from "@/views/Admin/Creatives/Associations/AssociationTableBase.vue";
import DialogDefault from "@/components/Content/Dialogs/Default.vue";
// @ts-ignore
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";

import {
	isRequired,
	isArrayRequired,
	isNumber,
	isDomain,
	isMinLength,
	isMaxLength,
	isAfterToday,
	isAfterCompare,
	isValidUrl,
	isFileMaxSize,
	isUploaded,
	isValidUrlNotRequire,
	isMax,
	isSameOrAfterToday,
	isMustValidated,
	isUrl,
	isMaxCustomLength,
} from "@/services/rule-services";
// @ts-ignore
import { TagCheck } from "@/interfaces/creative";
// @ts-ignore
import { Notification, MessageTypes } from "@/interfaces/proccess";
import { initRulesCreative } from "@/utils/initData";
import { mapActions, mapGetters } from "vuex";

import PreviewDevice from "@/components/Commons/Previews/Device/index.vue";
import { getDeviceOption, getMatchedTemplates } from "./utils";

// Configs to Date
const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export default Vue.extend({
	name: "OverviewCreative",

	props: {
		creative: {
			type: Object,
			default: function () {
				return {};
			},
		},
		resources: {
			type: Object,
			default: function () {
				return {};
			},
		},
		messages: {
			type: Object,
			default: function () {
				return {};
			},
		},
		config: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},

	components: {
		CardTextField,
		CardAutocomplete,
		CardSwitch,
		DatePicker,
		DataPickerStarTime,
		RadioButton,
		Separator,
		DividerForm,
		CardActions,
		CardTextarea,
		ArrayListItem,
		VastEvent,
		ProgressEvent,
		AssociatedLineForm,
		AssociationTable,
		PreviewDevice,
		DialogDefault,
		ButtonTooltip,
		AssociationTableBase
	},

	data: () => ({
		title: "OverviewCreative",
		valid: true,
		rules: initRulesCreative(),
		renderImage: true,
		showUpdatePreview: false,
		interval: 0,
		bgColor: "#eee",
		isChangedSelected: false,
		modal: {
			previewOpen: false,
		},
		previewData: {
			size: "small",
			rotate: false,
		},
	}),

	created() {},

	mounted() {
		this.$nextTick(async function () {
			this.resetValidation();
			this.clearError();
		});
	},

	computed: {
		...mapGetters("profile", ["isRolReport"]),

		isReadOnly(){
			return this.isRolReport;
		},

		getRules() {
			return {
				isRequired,
				isArrayRequired,
				isNumber,
				isDomain,
				isMinLength,
				isMaxLength,
				isAfterToday,
				isAfterCompare,
				isValidUrl,
				isFileMaxSize,
				isUploaded,
				isValidUrlNotRequire,
				isMax,
				isSameOrAfterToday,
				isMustValidated,
				isUrl,
				isMaxCustomLength,
			};
		},

		/**
		 * STRING RULES
		 */

		getErrors() {
			return this.$store.state.proccess.errors;
		},

		/**
		 * Getters
		 */

		isEdit() {
			return Boolean(this.creative?.id);
		},

		getToday() {
			return this.moment().format(DEFAULT_DATE_TIME_FORMAT);
		},

		getMinDate() {
			return this.moment(this.creative.start_date).format(
				"YYYY-MM-DD HH:mm:ss"
			);
		},

		getCreativeTemplates() {
			return filter(
				this.$store.state.creative.creative_templates,
				function (template) {
					return (
						template.header === "Display" ||
						template.header === "Video" ||
						template.group === "Video" ||
						template.group === "Display"
					);
				}
			);
		},

		getMatchedTemplates() {
			const templates: any[] = this.getCreativeTemplates;
			return getMatchedTemplates(templates);
		},

		getPreviewImage(): string {
			if (this.isEdit && !this.isChangedSelected)
				return this.creative.creative_asset_url;

			let assetSelect = this.getAssetSelected(
				this.creative.creative_ad_content.primary_asset_id
			);

			const deviceConfig = this.getDeviceOptionTypeSelected;

			if (assetSelect && deviceConfig.type === "video")
				return assetSelect?.asset_url;

			return (
				assetSelect?.thumbnail_url ||
				"https://i.stack.imgur.com/y9DpT.jpg"
			);
		},

		getDeviceOptionTypeSelected() {
			return getDeviceOption(this.creative.creative_template_id);
		},

		showUpdateAssetByTemplate(): Boolean {
			if (this.creative.creative_template_id === 21) return true;
			return (
				this.creative.budget_type_id === 1 &&
				this.showFieldByTemplateId("primary_asset_id")
			);
		},

		showPrimaryAssetByTemplate(): boolean {
			if (this.creative.creative_template_id === 21) return true;
			return (
				this.creative.budget_type_id === 2 &&
				this.showFieldByTemplateId("primary_asset_id")
			);
		},

		getTagValid() {
			return this.config.tag_validated ? this.config.tag_valido : "";
		},
		getMimeTypes() {
			if (!isEmpty(this.$store.state.creative.mime_types)) {
				return this.getMIMETypes(this.creative.creative_template_id);
			}
		},

		isDisabledCompanionHtml() {
			this.creative.creative_ad_content.companion_html = null;
			return this.creative.creative_ad_content.secondary_asset_id
				? true
				: false;
		},

		isDisabledCompanionSize() {
			this.creative.creative_ad_content.companion_size_id = null;
			return this.creative.creative_ad_content.secondary_asset_id
				? true
				: false;
		},

		stylesDrag() {
			return { "background-color": this.bgColor };
		},

		checkToLineItem() {
			return !!this.$route?.params?.lineItem?.id;
		},

		calcWidth() {
			return Math.floor(this.$vuetify.breakpoint.width * 50) / 100;
		},

		getBudgetHelpText() {
			if (isUndefined(this.creative.budget_type_id))
				return this.tooltipData(this.$t('creative.help.new'));

			if(this.creative.budget_type_id == 1){
				return this.tooltipData(this.$t('creative.help.new'))
			}else{
				return this.tooltipData(this.$t('creative.help.existing'))
			}
		},
	},

	beforeDestroy() {
		this.resetInterval();
	},

	methods: {
		...mapActions("proccess", ["setLoadingField"]),

		clearError() {
			this.$store.state.proccess.errors = {};
		},

		tooltipData(params: any) {
			return {
				icon: "mdi-information-outline",
				text: params,
				to: "",
				right: params?.right ?? true,
			};
		},

		async forceRenderPreview(interval: number) {
			this.interval = setInterval(async () => {
				await this.reRenderPreview();
			}, interval);
		},

		async resetInterval() {
			clearInterval(this.interval);
			this.internal = null;
		},

		async reRenderPreview() {
			this.renderImage = false;
			this.$nextTick(async () => {
				this.renderImage = true;
			});
		},

		async handleImgLoad(event: any, type: string) {
			this.showUpdatePreview = false;
		},

		handleChangedSelected() {
			this.isChangedSelected = true;
		},

		async handlerImgError(event: any, type: string) {
			this.showUpdatePreview = true;
		},

		getError(index: any) {
			return getError(this.getErrors, index);
		},

		hasError(index: string | number) {
			return this.errors.hasOwnProperty(index);
		},

		showFieldByTemplateId(input: string) {
			return hasFieldByTemplateId(
				this.creative.creative_template_id,
				input
			);
		},

		getAssetSelected(id: Number) {
			return find(
				this.resources.creative_ad_content.primary_asset_id,
				function (s) {
					return s.id === id;
				}
			);
		},

		async getFilterEtiquetaAsset(thum: boolean) {
			if (thum) {
				return ".png, .jpeg, .bmp, .gif, .jpg";
			} else {
				let templateId = this.creative.creative_template_id;
				switch (templateId) {
					case 1:
					case 4:
					case 5:
					case 13:
						return ".png, .jpeg, .bmp, .gif, .jpg";
					case 21:
						return ".html";
					case 3:
					case 6:
					case 20:
						return ".flv, .mp4, .webm, .swf, .js";
					default:
						return "image/*";
				}
			}
		},

		getMIMETypes(id_Template: Number) {
			switch (id_Template) {
				case 4:
				case 5:
				case 13:
				case 21:
					return this.getMIMEByBanner();
				case 6:
				case 20:
					return this.getMIMEByVAST();
				default:
					return [];
			}
		},

		getMIMEByBanner() {
			let filtro = filter(
				this.$store.state.creative.mime_types,
				function (mime) {
					return (
						[
							"image/jpeg",
							"image/jpg",
							"image/png",
							"image/gif",
							"text/javascript",
						].indexOf(mime.extra) !== -1
					);
				}
			);
			return filtro;
		},

		getMIMEByVAST() {
			let filtro = filter(
				this.$store.state.creative.mime_types,
				function (mime) {
					return (
						[
							"video/x-flv",
							"text/javascript",
							"application/javascript",
							"video/mp4",
							"application/x-shockwave-flash",
							"video/webm",
						].indexOf(mime.extra) !== -1
					);
				}
			);
			return filtro;
		},

		async clickUpload(thumbnail: boolean) {
			if (!this.creative.creative_advertiser.advertiser_id) {
				this.$emit("config", { key: "snackbar", value: true });
				this.$emit("config", {
					key: "texto_snackbar",
					value: "You must select an Advertiser",
				});
				return;
			}

			if (isNull(this.creative.file) || isUndefined(this.creative.file)) {
				this.$emit("config", { key: "snackbar", value: true });
				this.$emit("config", {
					key: "texto_snackbar",
					value: "You must select a file",
				});
				return;
			}

			let templateId = this.creative.creative_template_id;

			let formData = new FormData();
			formData.append(
				"advertiser_id",
				this.creative.creative_advertiser.advertiser_id
			);
			formData.append("file", this.creative.file);

			this.$emit("create-asset", { formData, thumbnail, templateId });

			await this.updateCreative({
				key: "file",
				value: null,
			});
		},

		getDetectAttributes() {
			if (
				isEmpty(this.creative.creative_ad_content.tag) ||
				isNull(this.creative.creative_ad_content.tag)
			)
				return;
			this.creative.creative_ad_content.video_duration =
				"Enter customizer duration";

			this.fetchResource("tag", this.creative.creative_ad_content.tag);
		},

		/**
		 * Validations
		 */
		resetValidation() {
			let form = this.$refs.form;
			form.resetValidation();
		},

		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},

		/**
		 * Handler Submit
		 * @param action
		 */
		async handleSubmit(event: any) {
			try {
				await this.handleValidations();

				if (!(await this.validate())) return;

				await this.$emit("store", { continue: false });
			} catch (error) {
				console.error("handleSubmit", { error });
			}
		},

		/**
		 * Handler Btn Action
		 * @param action
		 */
		async handleAction(action: { type: any }) {
			switch (action.type) {
				case "save":
					await this.handleValidations();

					if (!(await this.validate())) return;

					this.$emit("store", { continue: false });
					break;

				case "save-continue":
					await this.handleValidations();

					if (!(await this.validate())) return;

					this.$emit("store", { continue: true });
					break;

				case "cancel":
					if (this.checkToLineItem) {
						this.$router.push({
							name: this.$route.params.lineItem.to,
							params: { id: this.$route.params.lineItem.id },
							query: {
								section: this.$route.params.lineItem.section,
							},
						});
					} else {
						this.$router.push({ name: "CreativesIndex" });
					}
					break;
			}
		},

		// fetching
		async fetchResource(key: string, value?: any) {
			this.$emit(`fetch-resource`, { resource: key, value: value });
		},
		async updateResource(params: { key: string; value: any }) {
			this.$emit(`update-resource`, {
				resource: params.key,
				value: params.value,
			});
		},
		async updateCreative(params: {
			key: string;
			content?: string;
			value: any;
		}) {
			this.$emit(`update-creative`, {
				key: params.key,
				content: params.content,
				value: params.value,
			});
		},

		copyTag() {
			if (isEmpty(this.config.tag_valido)) return;
			navigator.clipboard
				.writeText(this.config.tag_valido)
				.then(() => {
					this.$emit("config", { key: "snackbar", value: true });
					this.$emit("config", {
						key: "texto_snackbar",
						value: "Copied to clipboard",
					});
				})
				.catch((error) => {
					this.$emit("config", { key: "snackbar", value: true });
					this.$emit("config", {
						key: "texto_snackbar",
						value: "Error when copying to clipboard",
					});
				});
		},

		async validateTag() {
			try {
				const tag: TagCheck = {
					creative_rule_id:
						this.creative.creative_ad_content.creative_rule_id,
					creative_content_tag: this.creative.creative_ad_content.tag,
				};
				await this.setLoadingField(true);
				const response = await this.dispatchValidateTag(tag);
				const { content } = response;
				await this.assignTag(content);
				await this.setLoadingField(false);
				await this.addTagValidations();
				this.setNotification(this.getMsgSuccess());
			} catch (error) {
				this.$emit("config", { key: "tag_validated", value: false });
				this.$emit("config", { key: "tag_valido", value: "" });
				console.error("create::validateTag", { error: error });
				await this.setLoadingField(false);
			}
		},

		async assignTag(tag: any) {
			this.$emit("config", {
				key: "tag_validated",
				value: !isEmpty(tag) && !isUndefined(tag),
			});
			this.$emit("config", { key: "tag_valido", value: tag });

			if (this.config.tag_validated) {
				await this.updateCreative({
					content: "creative_ad_content",
					key: "tag",
					value: tag,
				});
			}
		},

		async updateThumbnail(id: number | null) {
			if (isNull(id)) {
				this.$emit("config", { key: "thumbnail_url_val", value: "" });
				return;
			}

			const resp = find(
				this.resources.creative_exchange_options.thumbnail_id,
				function (a) {
					return a.id === id;
				}
			);

			this.$emit("config", {
				key: "thumbnail_url_val",
				value: resp?.thumbnail_url || "",
			});
		},

		/**
		 * Notification
		 */
		setNotification(notification: Notification) {
			return this.$store.dispatch(
				"proccess/setNotification",
				notification,
				{
					root: true,
				}
			);
		},

		getMsgSuccess() {
			return {
				title: "Success",
				type: MessageTypes.SUCCESS,
				show: true,
				btn_text: MessageTypes.CONTINUE,
			};
		},

		/**
		 * Dispatch Validate Tag
		 */
		async dispatchValidateTag(tag: TagCheck) {
			return this.$store.dispatch("creative/validateTag", tag);
		},

		async handleInputBitRate(event: any) {
			await this.updateVideoBitRate(event.target.value);
		},

		async updateVideoBitRate(params) {
			if (isNaN(Number(params))) return;
			const obj = {
				id: Number(params),
				value: String(params + " kbs"),
			};
			if (
				isUndefined(
					find(
						this.resources.creative_attributes.video_bit_rates,
						obj
					)
				)
			) {
				this.resources.creative_attributes.video_bit_rates.push(obj);
			}
			if (
				this.creative.creative_attributes.video_bit_rates.indexOf(
					Number(params)
				) === -1
			) {
				this.creative.creative_attributes.video_bit_rates.push(
					Number(params)
				);
			}
		},

		async handleEnter(event: any, key: string, content?: string) {
			if (event.keyCode === 13) {
				if (isNull(event.target.value) || isEmpty(event.target.value))
					return;
				await this.updateCreative({
					content: content,
					key: key,
					value: event.target.value,
				});
			}
		},

		async proccessAppend(item: any, params: { key: string; args: string }) {
			const ignores: Array<string> = ["vast_event", "progress_event"];

			this.$emit("message", { key: params.key, value: "" });

			if (isEmpty(item)) return;

			if (!ignores.includes(params.key) && !isUrl(item)) {
				this.$emit("message", {
					key: params.key,
					value: this.$t("domain"),
				});
				return;
			}

			const index = this.getIndexByData(
				this.creative.creative_addon_settings[params.args],
				item
			);

			if (!ignores.includes(params.key) && index > -1) {
				this.$emit("message", {
					key: params.key,
					value: this.$t("url-added"),
				});
				return;
			}

			let args: Array<any> =
				this.creative.creative_addon_settings[params.args];
			args.push(item);

			if (params.args) {
				await this.updateCreative({
					content: "creative_addon_settings",
					key: params.args,
					value: args,
				});
			}

			if (params.key) {
				await this.updateCreative({
					content: "creative_addon_settings",
					key: params.key,
					value: "",
				});
			}
		},

		async handleAppend(event: any, params: { key?: any; args: any }) {
			if (event instanceof KeyboardEvent) {
				event.preventDefault();
				event.stopPropagation();
				if (event.keyCode == 13) {
					// @ts-ignore
					await this.proccessAppend(event.target.value, params);
				}
				return;
			}

			let item = event;

			switch (params.key) {
				case "vast_event":
					item = {
						event: "",
						url: "",
					};
					break;

				case "progress_event":
					item = {
						event: "",
						url: "",
					};
					break;

				default:
					break;
			}

			await this.proccessAppend(item, params);
		},

		async handleDelete(
			event: any,
			params: { key: string; content: string }
		) {
			if (!event) return;

			let data: Array<string> = this.creative[params.content][params.key];

			const index = this.getIndexByData(data, event);

			if (index < 0) return;

			data.splice(index, 1);

			await this.updateCreative({
				content: params.content,
				key: params.key,
				value: data,
			});
		},

		async updateStateToogle(event: any, key: string, content: string) {
			if (this.creative[content][key]) {
				await this.updateCreative({
					content: content,
					key: key,
					value: !event,
				});
			}
		},

		getIndexByData(data: Array<string>, item: string) {
			return data.indexOf(item);
		},

		setDeleteAll(value: any) {
			if (!isNull(value)) {
				this.config.delete_all_performe = true;
			} else {
				this.config.delete_all_performe = false;
			}
		},

		/**
		 * VALIDATIONS
		 */

		/**
		 * Reset validations
		 */
		async resetRules() {
			this.rules = initRulesCreative();
		},

		async setRulesExpandable(expandable_type_id: number) {
			if (!!expandable_type_id) {
				this.rules.creative_attributes.expandable_directions = [
					this.getRules.isArrayRequired,
				];
			} else {
				this.rules.creative_attributes.expandable_directions = [];
			}
		},

		/**
		 * Set rules by template
		 * @param creative_template_id
		 */
		async setRulesByTemplate(creative_template_id: number) {
			await this.setRulesInBannerInVideoId();
			await this.setRulesCreativeSize();

			switch (creative_template_id) {
				case 1: // Banner Image
					await this.setRulesBannerImage();
					break;

				case 3: // VastInline
					await this.setRulesVastInline();
					break;

				case 4: // JsTag
					await this.setRulesDefaultTags();
					break;

				case 5: // IframeTag
					await this.setRulesDefaultTags();
					break;

				case 6: // VastWrapper
					await this.setRulesVastWrapper();
					break;

				case 13: // MraidTag
					await this.setRulesDefaultTags();
					break;

				case 14: //
					break;

				case 15: //
					break;

				case 16: //
					break;

				case 17: //
					break;

				case 18: //
					break;

				case 19: //
					break;

				case 20: // VastWrapper WithMOATViewability
					await this.setRulesVastWrapper();
					break;

				case 21: // HTML5
					await this.setRulesHtml5();
					break;

				case 22: //
					break;
			}
		},

		/**
		 * Set dates rules
		 */
		async setDefaultDates() {
			if (this.creative.start_date instanceof Date) {
				this.rules.start_date = [
					this.getRules.isRequired,
					this.getRules.isSameOrAfterToday(this.creative?.start_date),
				];
				this.rules.end_date = [
					this.getRules.isRequired,
					this.getRules.isAfterCompare(
						this.creative.end_date,
						this.creative.start_date
					),
				];
			}
		},

		async setRulesInBannerInVideoId() {
			const hasRule: Boolean =
				this.showFieldByTemplateId("in_banner_video_id");
			// in_banner_video_id
			if (hasRule) {
				await this.fetchResource("in_banner_video_id");
				this.rules.creative_attributes.in_banner_video_id = [
					this.getRules.isRequired,
					this.getRules.isNumber,
				];
			} else {
				this.rules.creative_attributes.in_banner_video_id = [];
			}
		},

		async setRulesCreativeSize() {
			const hasRule: Boolean = this.showFieldByTemplateId("size_id");

			// creative_size_id
			if (hasRule) {
				this.rules.creative_size_id = [
					this.getRules.isRequired,
					this.getRules.isNumber,
				];
			} else {
				this.rules.creative_size_id = [];
			}
		},

		/**
		 * Set commons rules
		 */
		async setRulesDefault() {
			// creative_template_id
			this.rules.creative_template_id = [this.getRules.isRequired];

			// name
			this.rules.name = [
				this.getRules.isRequired,
				this.getRules.isMinLength,
				this.getRules.isMaxLength,
			];

			// advertiser
			this.rules.creative_advertiser = {
				advertiser_id: [this.getRules.isRequired],
				domain: [this.getRules.isRequired, this.getRules.isDomain],
				category_id: [this.getRules.isRequired],
				app_bundle: [],
			};

			// budget_type_id
			this.rules.budget_type_id = [this.getRules.isRequired];

			// budget_type_id
			this.rules.budget_type_id = [this.getRules.isRequired];

			// creative_exchange_options
			const landing_page_url =
				this.creative.creative_exchange_options.landing_page_url;

			this.rules.creative_exchange_options = {
				vendors: [],
				landing_page_url:
					isEmpty(landing_page_url) || isNull(landing_page_url)
						? []
						: [this.getRules.isValidUrl],
				thumbnail_id: this.showFieldByTemplateId("thumbnail_id")
					? [this.getRules.isRequired]
					: [],
			};

			await this.setRulesExpandable(
				this.creative.creative_attributes.expandable_type_id
			);
		},

		/**
		 * Validations
		 * Banner Image
		 * 1
		 */
		async setRulesBannerImage() {
			// file (assets)
			switch (this.creative.budget_type_id) {
				case 1:
					this.rules.file = [
						this.getRules.isRequired,
						this.getRules.isFileMaxSize,
						this.getRules.isUploaded,
					];
					break;

				case 2:
					this.rules.creative_ad_content.primary_asset_id = [
						this.getRules.isRequired,
					];

					break;
			}

			// click_url
			this.rules.creative_ad_content.click_url = [
				this.getRules.isRequired,
				this.getRules.isValidUrl,
			];
		},

		/**
		 * Validations
		 * Vast Inline
		 * 3
		 */
		async setRulesVastInline() {
			// file (assets)
			switch (this.creative.budget_type_id) {
				case 1:
					this.rules.file = [
						this.getRules.isRequired,
						this.getRules.isFileMaxSize,
						this.getRules.isUploaded,
					];
					break;

				case 2:
					this.rules.creative_ad_content.primary_asset_id = [
						this.getRules.isRequired,
					];

					/*
					this.rules.creative_ad_content.secondary_asset_id = [
						this.getRules.isRequired,
					];*/

					break;
			}

			// click_url
			this.rules.creative_ad_content.click_url = [
				this.getRules.isRequired,
				this.getRules.isValidUrl,
			];

			// companion html
			this.rules.creative_ad_content.companion_html = this.creative
				.creative_ad_content.companion_size_id
				? [this.getRules.isRequired]
				: [];

			// companion size
			this.rules.creative_ad_content.companion_size_id = this.creative
				.creative_ad_content.companion_html
				? [this.getRules.isRequired]
				: [];

			// video_duration
			this.rules.creative_ad_content.video_duration = [
				this.getRules.isRequired,
				this.creative.creative_ad_content.video_duration > 0
					? this.getRules.isMax(
							this.creative.creative_ad_content.video_duration,
							60
					  )
					: true,
			];
		},

		/**
		 * Validations
		 * JsTag | IframeTag | Mraid Tag
		 * 4 | 5 | 13
		 */
		async setRulesDefaultTags() {
			// creative_rule_id
			this.rules.creative_ad_content.creative_rule_id = [
				this.getRules.isRequired,
				this.getRules.isNumber,
			];

			// mime_type_id
			this.rules.creative_attributes.mime_type_id = [
				this.getRules.isRequired,
				this.getRules.isNumber,
			];

			// expandable_type_id
			/*
			if (this.isEdit) {
				this.rules.creative_attributes.expandable_type_id = [
					this.getRules.isRequired,
					this.getRules.isNumber,
				];
			} else {
				this.rules.creative_attributes.expandable_type_id = [];
			}

			await this.setRulesExpandable(
				this.creative.creative_attributes.expandable_type_id
			);*/

			// thumbnail_id
			this.rules.creative_exchange_options.thumbnail_id = [
				this.getRules.isRequired,
				this.getRules.isNumber,
			];
		},

		/**
		 * Validations
		 * Vast Wrapper
		 * 6
		 */
		async setRulesVastWrapper() {
			this.rules.creative_attributes.video_mime_types = [
				this.getRules.isRequired,
			];

			// video_duration
			this.rules.creative_ad_content.video_duration = [
				this.getRules.isRequired,
				this.creative.creative_ad_content.video_duration > 0
					? this.getRules.isMax(
							this.creative.creative_ad_content.video_duration,
							60
					  )
					: true,
			];
		},

		/**
		 * Validations
		 * Html5
		 * 6
		 */
		async setRulesHtml5() {
			// primary_asset_id
			this.rules.creative_ad_content.primary_asset_id = [
				this.getRules.isRequired,
			];

			// mime_type_id
			this.rules.creative_attributes.mime_type_id = [
				this.getRules.isRequired,
				this.getRules.isNumber,
			];

			// expandable_type_id
			if (this.isEdit) {
				this.rules.creative_attributes.expandable_type_id = [
					this.getRules.isRequired,
					this.getRules.isNumber,
				];
			} else {
				this.rules.creative_attributes.expandable_type_id = [];
			}

			// thumbnail_id
			this.rules.creative_exchange_options.thumbnail_id = [
				this.getRules.isRequired,
				this.getRules.isNumber,
			];
		},

		/**
		 * Tag Validations
		 */
		async addTagValidations() {
			const hasRule: Boolean = this.showFieldByTemplateId("tag");
			if (hasRule) {
				this.rules.creative_ad_content.tag = [
					this.getRules.isRequired,
					isMustValidated(this.config.tag_validated),
				];
			} else {
				this.rules.creative_ad_content.tag = [];
			}
		},

		async handleValidations() {
			// reset all rules
			await this.resetRules();

			// common rules for all templates
			await this.setRulesDefault();

			// rules for all dates
			await this.setDefaultDates();

			// rules tag
			await this.addTagValidations();

			// rules by template
			await this.setRulesByTemplate(this.creative.creative_template_id);
		},

		async checkTypeFile(typeFile: string, types: string) {
			const data = typeFile.split("/");
			if (isUndefined(data[1])) {
				return false;
			} else {
				return types.includes(data[1]);
			}
		},

		async dropHandler(event: any) {
			this.bgColor = "#eee";
			let file = event?.dataTransfer?.files[0];
			if (!event || !file) return;
			const filterTypes = await this.getFilterEtiquetaAsset(false);
			if (await this.checkTypeFile(file.type, filterTypes)) {
				this.creative.file = file;
				await this.clickUpload(false);
			}
		},

		dragOverHandler(event) {
			if (event) {
				this.bgColor = "#D1D1D1";
			}
		},

		dragLeaveHandle(event) {
			if (event) {
				this.bgColor = "#eee";
			}
		},

		handleToggleModal() {
			this.modal.previewOpen = !this.modal.previewOpen;
		},

		handlePreviwData(event: any) {
			if (!isUndefined(event.size)) {
				this.previewData.size = event.size;
			}
			if (!isUndefined(event.rotate)) {
				this.previewData.rotate = event.rotate;
			}
		},

		updateTable(){
			this.$refs.table_ass.getPaginated();
		}
	},

	watch: {
		async "creative.creative_template_id"(val: number | undefined) {
			if (!val) return;
			await this.updateResource({
				key: "creative_template_id",
				value: val,
			});
			this.asset = val === 21 ? "HTML Asset" : "Assets";

			await this.setRulesInBannerInVideoId();
		},

		async "creative.creative_size_id"(val) {
			if (!val) return;

			const creativeSizes = this.resources.creative_size_id;

			if (!creativeSizes) return;

			const selectedSize = find(creativeSizes, function (s) {
				return s.id == val;
			});

			if (!selectedSize) return;

			let creativeAttributes = this.creative.creative_attributes;

			let newCreativeAttributes = {
				size_id: selectedSize.id,
				width: selectedSize.with,
				height: selectedSize.height,
			};

			creativeAttributes = Object.assign(
				creativeAttributes,
				newCreativeAttributes
			);

			await this.updateCreative({
				key: "creative_attributes",
				value: creativeAttributes,
			});
		},

		async "creative.creative_advertiser.advertiser_id"(val) {
			if (!val) return;

			if (!this.resources.creative_advertiser.advertiser_id) return;

			const selectedAdvertiser = find(
				this.resources.creative_advertiser.advertiser_id,
				function (s) {
					return s.id == val;
				}
			);

			if (!selectedAdvertiser) return;

			await this.updateCreative({
				content: "creative_ad_content",
				key: "primary_asset_id",
				value: null,
			});

			await this.updateCreative({
				content: "creative_ad_content",
				key: "secondary_asset_id",
				value: null,
			});

			await this.updateCreative({
				content: "creative_ad_content",
				key: "thumbnail_id",
				value: null,
			});

			await this.updateCreative({
				key: "creative_advertiser",
				value: {
					advertiser_id: selectedAdvertiser.id,
					domain: selectedAdvertiser.domain,
					category_id: String(selectedAdvertiser.category_id),
					app_bundle: selectedAdvertiser.app_bundle,
				},
			});
		},

		async "creative.creative_ad_content.video_duration"(val, old) {
			const showVideoDurationVal: boolean =
				val === "Enter customizer duration" || val === "";

			const showVideoDurationOld: boolean =
				old === "Enter customizer duration" || val === "";

			if (!this.config.customVideoDuration) {
				this.$emit("config", {
					key: "customVideoDuration",
					value: showVideoDurationVal || showVideoDurationOld,
				});

				if (this.config.customVideoDuration) {
					await this.updateCreative({
						content: "creative_ad_content",
						key: "video_duration",
						value: "",
					});
				}
			}
		},

		async "creative.creative_exchange_options.thumbnail_id"(val) {
			await this.updateThumbnail(val);
		},

		async showUpdatePreview(val) {
			if (val) {
				await this.forceRenderPreview(4000);
			} else {
				await this.resetInterval();
			}
		},

		async "creative.creative_attributes.expandable_type_id"(val) {
			// expandable_directions
			// thisawait this.setRulesExpandable(val);
		},
	},
});
