import Vue from "vue";
// @ts-ignore
import OverviewForm from "@/views/Admin/Creatives/Dependencies/Forms/Overview.vue";
import { filter, find, first, isEmpty, isNull, isUndefined } from "lodash";
import {
	createCreativeConfig,
	createCreativeMessage,
	initCreative,
	initResourcesCreative,
	matchedPaths,
} from "@/utils/initData";
import Alertize from "@/components/Alertize.vue";
import {
	isRequired,
	isNumber,
	isDomain,
	isMinLength,
	isMaxLength,
	isAfterToday,
	isAfterCompare,
	isValidUrl,
	isFileMaxSize,
	isUploaded,
	isValidUrlNotRequire,
	isMax,
	isSameOrAfterToday,
} from "@/services/rule-services";
import { getAssetFilters } from "@/utils/resolveObjectArray";
import { MessageTypes, Notification } from "@/interfaces/proccess";
import { CreativeFilters } from "@/interfaces/creative";
import { getCreativeParsedByType } from "@/utils/fields";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { mapActions } from "vuex";

export default Vue.extend({
	name: "CreateCreative",
	props: {},
	components: { Alertize, OverviewForm },
	data: () => ({
		creative: initCreative(),
		resources: initResourcesCreative(),
		config: createCreativeConfig(),
		messages: createCreativeMessage(),
	}),
	async created() {},
	async mounted() {
		this.$nextTick(async () => {
			try {
				await this.setLoadingData(TypeLoading.loading);

				await this.fetchResource({ resource: "creative_type_id" });

				await this.fetchResource({ resource: "creative_template_id" });

				this.resources.assets_types =
					await this.dispatchCreativeResource("assets_types");

				if (this.hasParamID) {
					this.creative = await this.fetchCreative(this.getParamID);
				}

				await this.checkNullsValuesCreative();

				await this.setLoadingData();

				if (this.hasParamID) {
					await this.loadResources();
				}

				await this.setLoadingData();
			} catch (error) {
				console.error("mounted", { error });
				await this.setLoadingData();
			}
		});
	},
	async beforeDestroy() {},
	computed: {
		getParamID(): Number {
			return Number(this.$route.params.id);
		},

		hasParamID(): boolean {
			return !isNaN(this.getParamID);
		},

		getRules() {
			return {
				isRequired,
				isNumber,
				isDomain,
				isMinLength,
				isMaxLength,
				isAfterToday,
				isAfterCompare,
				isValidUrl,
				isFileMaxSize,
				isUploaded,
				isValidUrlNotRequire,
				isMax,
				isSameOrAfterToday,
			};
		},
		getAsset() {
			return this.$store.state.creative.asset;
		},
		getAddonsSetting() {
			return {
				addons: [],
				pixels: [],
				scripts: [],
				click_trackers: [],
				vast_events: [],
				progress_events: [],
			};
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("proccess", ["setLoadingField"]),
		
		async checkNullsValuesCreative() {
			if (!this.creative.creative_addon_settings) {
				this.creative.creative_addon_settings = this.getAddonsSetting;
			}
			if (!this.creative.creative_addon_settings.pixels) {
				this.creative.creative_addon_settings.pixels = [];
			}
			if (!this.creative.creative_addon_settings.scripts) {
				this.creative.creative_addon_settings.scripts = [];
			}
			if (!this.creative.creative_exchange_options.vendors) {
				this.creative.creative_exchange_options.vendors = [];
			}
		},

		async loadResources() {
			try {
				if (
					this.creative.creative_size_id ||
					this.creative.creative_attributes.size_id
				) {
					await this.fetchResource({ resource: "creative_size_id" });
					this.creative.creative_size_id = 
						String(this.creative.creative_size_id);
				}

				if (this.creative.creative_advertiser.advertiser_id) {
					await this.fetchResource({ resource: "advertiser_id" });
				}

				if (this.creative.creative_ad_content.creative_rule_id) {
					await this.fetchResource({ resource: "creative_rule_id" });
					this.creative.creative_ad_content.creative_rule_id = 
						String(this.creative.creative_ad_content.creative_rule_id);
				}

				if (this.creative.creative_ad_content.video_duration) {
					if (!this.resources.creative_ad_content.video_duration.
						some((item) => item.id == this.creative.creative_ad_content.video_duration))
					{
						this.config.customVideoDuration = true;
					} 
				}

				if (
					this.creative.creative_attributes.mime_type_id ||
					this.creative.creative_attributes.video_mime_types
				) {
					await this.fetchResource({ resource: "mime_type_id" });
				}

				if(this.creative.creative_attributes.audio_mime_types){
					await this.fetchResource({ resource: "audio_mime_types" });
				}

				if (this.creative.creative_attributes.video_api_id) {
					await this.fetchResource({ resource: "video_api_id" });
				}

				if (this.creative.creative_attributes.video_bit_rates) {
					await this.fetchResource({ resource: "video_bit_rates" });
				}

				if (this.creative.creative_attributes.expandable_type_id) {
					await this.fetchResource({
						resource: "expandable_type_id",
					});
				}

				if (this.creative.creative_attributes.expandable_directions) {
					await this.fetchResource({
						resource: "expandable_directions",
					});
				}

				if (this.creative.creative_exchange_options.vendors) {
					await this.fetchResource({ resource: "vendors" });
				}

				if (this.creative.creative_addon_settings.addons) {
					await this.fetchResource({ resource: "addons" });
				}

				if (this.creative.creative_ad_content.primary_asset_id) {
					await this.fetchResource({ resource: "primary_asset_id" });
				}

				if (this.creative.creative_ad_content.secondary_asset_id) {
					await this.fetchResource({
						resource: "secondary_asset_id",
					});
				}

				if (this.creative.creative_exchange_options.thumbnail_id) {
					await this.fetchResource({ resource: "thumbnail_id" });
				}

				if (this.creative.creative_attributes.in_banner_video_id) {
					await this.fetchResource({
						resource: "in_banner_video_id",
					});
				}

				if (this.creative.creative_ad_content.companion_size_id) {
					await this.fetchResource({
						resource: "companion_size_id",
					});
				}
			} catch (error) {
				console.error("loadResources", { error });
			}
		},

		async fetchCreative(id: number) {
			if (isNaN(id)) return;
			return this.$store.dispatch("creative/show", id);
		},

		/**
		 * HANDLERS
		 */
		async fetchResource(params: { resource: string; value?: string }) {
			this.setLoadingField(true);
			switch (params?.resource) {
				case "creative_type_id":
					if (isEmpty(this.$store.state.creative.creative_types)) {
						await this.dispatchCreativeResource("creative_type_id");
					}
					this.setLoadingField(false);

					break;

				case "creative_template_id":
					this.resources[params.resource] =
						await this.dispatchCreativeResource(params.resource);

					if (isEmpty(this.$store.state.creative.creative_types)) {
						await this.dispatchCreativeResource("creative_type_id");
					}

					this.setLoadingField(false);

					break;

				case "creative_size_id":
					if (isEmpty(this.resources[params.resource])) {
						this.resources[params.resource] =
							await this.dispatchCreativeResource(
								params.resource
							);
					}

					this.setLoadingField(false);

					break;

				case "advertiser_id":
					if (
						isEmpty(
							this.resources.creative_advertiser.advertiser_id
						)
					) {
						this.resources.creative_advertiser.advertiser_id =
							await this.dispatchCreativeResource(
								params.resource,
								{
									filters: { active: true },
									options: { sort: "name", order: "asc" },
								}
							);
					}

					if (
						isEmpty(this.resources.creative_advertiser.category_id)
					) {
						this.resources.creative_advertiser.category_id =
							await this.dispatchCreativeResource("category_id");
						this.creative.creative_advertiser.category_id = 
							String(this.creative.creative_advertiser.category_id);
					}

					this.setLoadingField(false);

					break;

				case "category_id":
					if (
						isEmpty(this.resources.creative_advertiser.category_id)
					) {
						this.resources.creative_advertiser.category_id =
							await this.dispatchCreativeResource(
								params.resource
							);
						this.creative.creative_advertiser.category_id = 
							String(this.creative.creative_advertiser.category_id);
					}

					this.setLoadingField(false);

					break;

				case "tag":
					const response = await this.dispatchCreativeResource(
						params.resource,
						{ url: params.value }
					);

					await this.updateFieldsDetectAttributes(response);
					this.setLoadingField(false);
					break;

				case "companion_size_id":
					let companions: Array<any> =
						this.resources.creative_ad_content.companion_size_id;
					if (isEmpty(companions)) {
						this.resources.creative_ad_content.companion_size_id =
							await this.dispatchCreativeResource(
								params.resource
							);
					}
					this.setLoadingField(false);

					break;

				case "primary_asset_id":
					await this.prepareFetchAssets(
						"primary_asset_id",
						"creative_ad_content"
					);
					this.setLoadingField(false);

					break;

				case "secondary_asset_id":
					await this.prepareFetchAssets(
						"secondary_asset_id",
						"creative_ad_content"
					);
					this.setLoadingField(false);
					break;

				case "mime_type_id":
					if (
						isEmpty(this.resources.creative_attributes.mime_type_id)
					) {
						this.resources.creative_attributes.mime_type_id =
							await this.dispatchCreativeResource(
								params.resource
							);
					}
					this.setLoadingField(false);
					break;

				case "video_mime_types":
					if (
						isEmpty(
							this.resources.creative_attributes.video_mime_types
						)
					) {
						this.resources.creative_attributes.video_mime_types =
							await this.dispatchCreativeResource(
								params.resource
							);
					}
					this.setLoadingField(false);
					break;

				case "audio_mime_types":
					if (isEmpty(this.resources.creative_attributes.audio_mime_types)) {
						this.resources.creative_attributes.audio_mime_types =
							await this.dispatchCreativeResource(
								params.resource
							);
					}
					this.setLoadingField(false);
					break;

				case "creative_rule_id":
					if (
						isEmpty(
							this.resources.creative_ad_content.creative_rule_id
						)
					) {
						this.resources.creative_ad_content.creative_rule_id =
							await this.dispatchCreativeResource(
								params.resource
							);
					}
					this.setLoadingField(false);
					break;

				case "expandable_type_id":
					if (
						isEmpty(
							this.resources.creative_attributes
								.expandable_type_id
						)
					) {
						this.resources.creative_attributes.expandable_type_id =
							await this.dispatchCreativeResource(
								params.resource
							);
					}
					this.setLoadingField(false);
					break;

				case "expandable_directions":
					if (
						isEmpty(
							this.resources.creative_attributes
								.expandable_directions
						)
					) {
						this.resources.creative_attributes.expandable_directions =
							await this.dispatchCreativeResource(
								params.resource
							);
					}
					this.setLoadingField(false);
					break;

				case "in_banner_video_id":
					if (
						isEmpty(
							this.resources.creative_attributes
								.in_banner_video_id
						)
					) {
						this.resources.creative_attributes.in_banner_video_id =
							await this.dispatchCreativeResource(
								params.resource
							);

						const inBannerVideoIds: Array<any> =
							this.resources.creative_attributes
								.in_banner_video_id;

						if (isEmpty(inBannerVideoIds)) return;

						const inVideoDefault: Array<any> = filter(
							inBannerVideoIds,
							function (o) {
								return o.value === "No video";
							}
						);

						const inVideoDefaultID = !isEmpty(inVideoDefault)
							? first(inVideoDefault).id
							: undefined;

						await this.updateResource({
							resource: "in_banner_video_id",
							value: inVideoDefaultID,
						});
					}
					this.setLoadingField(false);
					break;

				case "video_api_id":
					if (
						isEmpty(this.resources.creative_attributes.video_api_id)
					) {
						this.resources.creative_attributes.video_api_id =
							await this.dispatchCreativeResource(
								params.resource
							);
					}
					this.setLoadingField(false);
					break;

				case "video_bit_rates":
					if (
						isEmpty(
							this.resources.creative_attributes.video_bit_rates
						)
					) {
						this.resources.creative_attributes.video_bit_rates =
							await this.dispatchCreativeResource(
								params.resource
							);
						await this.updateVideoBitRate(this.creative.creative_attributes.video_bit_rates);
					}
					this.setLoadingField(false);
					break;

				case "vendors":
					if (
						isEmpty(
							this.resources.creative_exchange_options.vendors
						)
					) {
						this.resources.creative_exchange_options.vendors =
							await this.dispatchCreativeResource(
								params.resource
							);
					}
					this.setLoadingField(false);
					break;

				case "addons":
					if (!isEmpty(this.resources.creative_addon_settings.addons))
						return;

					this.resources.creative_addon_settings.addons =
						await this.dispatchCreativeResource(params.resource, {
							filters: {
								creative_type_id:
									this.creative.creative_type_id,
							} as CreativeFilters,
						});
						
					this.setLoadingField(false);
					break;

				case "thumbnail_id":
					await this.prepareFetchAssets(
						"thumbnail_id",
						"creative_exchange_options"
					);
					this.setLoadingField(false);
					break;

				default:
					this.setLoadingField(false);
					break;
			}
		},

		async prepareFetchAssets(key: string, content?: string) {
			if (
				!this.creative.creative_advertiser.advertiser_id ||
				!this.creative.creative_template_id
			)
				return;

			const data = {
				key: key,
				creative_templates: this.resources.creative_template_id,
				creative_template_id: this.creative.creative_template_id,
				asset_types: this.resources.assets_types,
				_advertiser_id: this.creative.creative_advertiser.advertiser_id,
			};

			const result = await this.dispatchCreativeResource("assets", {
				filters: await getAssetFilters(data),
			});

			if (content) {
				this.resources[content][key] = result;
			} else {
				this.resources[key] = result;
			}
		},

		/*
		 * Los datos que estan en mime Type y Bit rate se deben combinar
		 */
		async updateFieldsDetectAttributes(response: any) {
			if(response?.mime_types){
				const values = new Array();
				response?.mime_types.forEach(element => {
					if(this.resources.creative_attributes.mime_type_id.some(e => e.id == element)){
						values.push(element);
					}
				});
				await this.updateMimeTypes(values);
			}
			await this.updateVideoAndVideoApi(response);
			await this.updateVideoBitRate(response?.bitrates);
		},

		async updateVideoAndVideoApi(params: {
			durations?: any[];
			video_apis?: any[];
		}) {
			if (params?.durations) {
				this.creative.creative_ad_content.video_duration =
					params?.durations[0];
			}
			if (params?.video_apis) {
				this.creative.creative_attributes.video_api_id =
					params?.video_apis[0];
			}
			await this.dispatchCreativeResource("video_api_id");
		},

		/*
		 * Actualizar los mime Type
		 */
		async updateMimeTypes(params: any[] | undefined) {
			if (params) {
				params.map((item: any) => {
					if (
						this.creative.creative_attributes.video_mime_types.indexOf(
							item
						) === -1
					) {
						this.creative.creative_attributes.video_mime_types.push(
							item
						);
					}
				});
			}
		},

		async dataBitRate(params){
			console.log("updateVideoBitRate", {EVENT: params});
		},

		/*
		 * Actualizar los bit rate
		 */
		async updateVideoBitRate(params: any[]) {
			if (params) {
				params.map((item: any) => {
					const obj = {
						id: Number(item),
						value: String(item + " kbs"),
					};

					if (
						isUndefined(
							find(
								this.resources.creative_attributes
									.video_bit_rates,
								obj
							)
						)
					) {
						this.resources.creative_attributes.video_bit_rates.push(
							obj
						);
					}
					if (
						this.creative.creative_attributes.video_bit_rates.indexOf(
							item
						) === -1
					) {
						this.creative.creative_attributes.video_bit_rates.push(
							item
						);
					}
				});
			}
		},

		async getCreativeTypeByTemplate(idTemplate: any){
			return this.resources.creative_template_id.find((temp) => temp.id === idTemplate);
		},

		async updateResource(params: any) {
			switch (params?.resource) {
				case "creative_template_id":
					await this.updateCreativeType(
						await this.getCreativeTypeByTemplate(params.value)
					);
					/*
					await this.updateCreativeType(
						await this.getCreativeTypeByTemplateId(params.value)
					);*/

					break;

				case "in_banner_video_id":
					if (
						params.value &&
						!this.creative.creative_attributes.in_banner_video_id
					) {
						this.creative.creative_attributes.in_banner_video_id =
							params.value;
					}

					break;
			}
		},

		async handleUpdateCreative(params: any) {
			if (params.content) {
				this.creative[params.content][params.key] = params.value;
			} else {
				this.creative[params.key] = params.value;
			}
		},

		async updateCreativeType(response: any) {
			if (!response) return;

			this.creative.creative_type_id = String(response?.creative_type_id);
			// this.creative.creative_ad_content.primary_asset_id = null;
			// this.creative.creative_ad_content.secondary_asset_id = null;
			// this.creative.creative_ad_content.thumbnail_id = null;
		},

		getPath(resource: string): string {
			return matchedPaths()[resource];
		},

		async handleCreateAsset(params: {
			formData: FormData;
			thumbnail: boolean;
			templateId: Number;
		}) {
			try {
				await this.setLoadingData(TypeLoading.loading);

				await this.dispatchCreativeResource("create_asset", params);

				if (params.thumbnail) {
					await this.prepareFetchAssets(
						"thumbnail_id",
						"creative_exchange_options"
					);

					this.creative.creative_exchange_options.thumbnail_id =
						this.getAsset.id;

					await this.updateThumbnail(this.getAsset.id);
				} else {
					await this.prepareFetchAssets(
						"primary_asset_id",
						"creative_ad_content"
					);

					this.creative.creative_ad_content.primary_asset_id =
						this.getAsset.id;

					this.creative.budget_type_id = 2;
				}

				await this.setLoadingData();

				this.setNotification(this.getMsgSuccess());
			} catch (error) {
				console.error("createAsset", { error: error });
				await this.handleConfig({
					key: "thumbnail_url_val",
					value: "",
				});
				await this.setLoadingData();
			}
		},

		async updateThumbnail(id: number | null) {
			if (isNull(id)) {
				await this.handleConfig({
					key: "thumbnail_url_val",
					value: "",
				});
				return;
			}

			const resp = find(
				this.resources.creative_exchange_options.thumbnail_id,
				function (a) {
					return a.id === id;
				}
			);

			await this.handleConfig({
				key: "thumbnail_url_val",
				value: resp?.thumbnail_url || "",
			});
		},

		setNotification(notification: Notification) {
			return this.$store.dispatch(
				"proccess/setNotification",
				notification,
				{
					root: true,
				}
			);
		},

		getMsgSuccess() {
			return {
				title: "Success",
				type: MessageTypes.SUCCESS,
				show: true,
				btn_text: MessageTypes.CONTINUE,
			};
		},

		async getCreativeTypeByTemplateId() {
			return await this.$store.dispatch(
				"creative/getCreativeTypeByTemplateId",
				{
					creativeTypes: this.$store.state.creative.responseTemplates,
					creative_template_id: this.creative.creative_template_id,
				}
			);
		},

		/**
		 * Update config data
		 * @param event: { key: string, value: string }
		 */
		async handleConfig(event: { key: string; value: any }) {
			this.config[event.key] = event.value;
		},

		/**
		 * Update config data
		 * @param event: { key: string, value: string }
		 */
		async handleMessage(event: { key: string; value: any }) {
			this.messages[event.key] = event.value;
		},

		/**
		 * FETCH
		 */
		async dispatchCreativeResource(resource: string, params?: any) {
			return this.$store.dispatch(`${this.getPath(resource)}`, params);
		},

		/**
		 * Dispatch Create
		 */
		async dispatchCreative(params: { continue: boolean; creative: any }) {
			return this.$store.dispatch("creative/CreateNewCreative", params);
		},

		/**
		 * Dispatch Update
		 */
		async dispatchUpdate(params: { continue: boolean; creative: any }) {
			return this.$store.dispatch("creative/UpdateCreative", params);
		},

		/**
		 * Store creative
		 * @param params: { continue: boolean }
		 */
		async handleStoreCreative(params: { continue: boolean }) {
			try {
				if (!this.creative.creative_template_id) return;

				await this.setLoadingData(TypeLoading.loading);

				// formatted creative data per template
				const creativeParsed = await getCreativeParsedByType({
					data: this.creative,
					edit: this.hasParamID,
				});

				const creativeParsedByTemplate =
					creativeParsed[this.creative.creative_template_id];

				if (!creativeParsedByTemplate) return;

				const resource = this.hasParamID ? "update" : "store";

				await this.dispatchCreativeResource(resource, {
					continue: params.continue,
					creative: creativeParsedByTemplate,
				});

				await this.setLoadingData();
			} catch (error) {
				console.error("handleStoreCreative", { error });
				await this.setLoadingData();
			}
		},
	},
});
