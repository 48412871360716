
	import { isEmpty } from "lodash";
	import Vue from "vue";
	import { isRequired, isValidUrl } from "../../services/rule-services";
	import CardTextField from "../../components/Content/CardTextField.vue";

	export default Vue.extend({
		name: "ProgressEvent",
		props: {
			items: {
				type: Array,
				default: function () {
					return [];
				},
			},
		},
		components: { CardTextField },
		data: () => ({}),
		created() {},
		mounted() {},
		computed: {
			getRules() {
				return {
					isRequired,
					isValidUrl,
				};
			},
			hasItems() {
				return !isEmpty(this.items);
			},
			getItems() {
				return this.items;
			},
		},
		methods: {
			deleteItem(item: any) {
				this.$emit("delete-item", item);
			},
		},
	});
