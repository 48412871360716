export enum OrientationType {
	VERTICAL = 'vertical',
	HORIZONTAL = 'horizontal',
}

export enum DeviceSize {
	LARGE = 'large',
	MEDIUM = 'medium',
	SMALL = 'small',
}

export enum DeviceConfig {
	ROTATE = 'rotate',
}

export enum DeviceAction {
	ACTIVE = 'active',
}