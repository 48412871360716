
	export default {
		name: "DividerForm",
		props: {
			name: {
				type: String,
				default: "Divider",
			},
		},
		data: function () {
			return {};
		},
		mounted() {},
		computed: {},
		methods: {},
	};
